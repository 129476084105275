<template>
  <div class="EduHome">
    <EduHeader />
    <main class="containerMain">
      <NavInfo />
      <div class="containerMainRight" style="padding-left: 60px">
        <div class="WrongQuestionCenter">
          <!-- <div class="WrongQuestionInfo">
            <div class="WrongQuestionInfo-title">温馨提示</div>
            <div class="WrongQuestionInfo-font">
              根据您的报告统计出的知识点错误<span>推荐您购买第三章</span>
            </div>
          </div> -->
          <div class="WrongQuestion-Title">
            <div class="WrongQuestion-font">
              错题集列表({{ menuList[index].name }})
            </div>
            <div class="WrongQuestion-select">
              <div class="selectValue">
                <span>自定义排序方式</span>
                <i><img src="~@/assets/WrongQuestion/iconsx.png" /></i>
              </div>
              <dl>
                <!-- <dd @click="handleOrderChange">
                  时间<span v-if="queryForm.order == '-created'">升序</span>
                  <span v-else>降序</span>排序
                </dd> -->
                <dd
                  v-for="(menu, menuIndex) in menuList"
                  :key="menu.id"
                  @click="handleOrderChange(menu, menuIndex)"
                  :class="{ on: menuIndex == index }">
                  {{ menu.name }}
                </dd>
                <!-- <dd>收藏时间排序</dd>
                <dd>收藏时间排序</dd> -->
              </dl>
            </div>
          </div>
          <!-- <div class="WrongQuestion-or-title">
            <div class="orTitle-right">
              <i><img src="~@/assets/WrongQuestion/iconsx1.png" /></i>
              <div>
                <i><img src="~@/assets/WrongQuestion/iconsx2.png" /></i>
                <span>移出错题集</span>
              </div>
            </div>
            <div class="orTitle-left">1.在GDP的几大部分构成中,不包括</div>
          </div>
          <div class="WrongQuestion-or">
            <dl>
              <dd>
                <div class="WrongQuestion-orDdTitle">
                  <label>
                    <input checked name="tm4" type="checkbox" />
                    <div>
                      约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                    </div>
                  </label>
                </div>
                <div class="WrongQuestion-info">
                  这是一致性的例子，而不是历史成本。
                </div>
              </dd>
              <dd>
                <div class="WrongQuestion-orDdTitle">
                  <label>
                    <input checked name="tm4" type="checkbox" />
                    <div>
                      约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                    </div>
                  </label>
                </div>
                <div class="WrongQuestion-info">
                  这是一致性的例子，而不是历史成本。
                </div>
              </dd>
              <dd>
                <div class="WrongQuestion-orDdTitle">
                  <label>
                    <input name="tm4" type="checkbox" />
                    <div>
                      约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                    </div>
                  </label>
                </div>
                <div class="WrongQuestion-info">
                  这是一致性的例子，而不是历史成本。
                </div>
              </dd>
              <dd>
                <div class="WrongQuestion-orDdTitle">
                  <label>
                    <input name="tm4" type="checkbox" />
                    <div>
                      约翰想知道在他的自行车店里，哪种清点最合适的方法。他决定使用与过去几年相同的方法，因为他的业务基本上以同样的方式运营。
                    </div>
                  </label>
                </div>
                <div class="WrongQuestion-info">
                  这是一致性的例子，而不是历史成本。
                </div>
              </dd>
            </dl>
          </div>
          <div class="WrongQuestion-or-title">
            <div class="orTitle-right">
              <i><img src="~@/assets/WrongQuestion/iconsx1.png" /></i>
              <div>
                <i><img src="~@/assets/WrongQuestion/iconsx2.png" /></i>
                <span>移出错题集</span>
              </div>
            </div>
            <div class="orTitle-left">1.在GDP的几大部分构成中,不包括</div>
          </div>
          <div class="WrongQuestion-or-text">
            <textarea></textarea>
          </div>
          <div class="WrongQuestion-explain on">
            <div class="explainTitle">错误:</div>
            <div class="wrongReason">甲公司的会计处理不正确。</div>
            <div class="explainCenter">
              签订合同部分300台可变现净值=300×
              (5-0.3)=1410(万元)成本=300×4.5=1350(万元)则签订合同部分不需要计捉存货跌价准备。未签订合同部分200台可变现净值=200×
              (4.5-0.4)=820(万元)。成本=200×4.5=900(万元)应计提存货跌价准备=900-
              820-10=70(万元) <br /><br />
              借：资产减值损失 70
              <br /><br />
              贷：存货跌价准备一B产品 70
              <br /><br />
              答案等于1350+820=2170(万元)
            </div>
          </div> -->
          <div
            v-for="(topicItem, topicIndex) in wrongTopicList"
            :key="topicIndex">
            <topic
              :topicData="topicItem"
              presentation="ONE_BY_ONE"
              :topicVOIndex="topicIndex"
              @set-wrong-topic-callback="setWrongTopicCallback"
              :topicIndex="topicIndex"
              refKind="Favorite"
              :refId="topicItem.id"
              arrowType="cancelWrong" />
          </div>
          <div v-if="wrongTopicList.length > 0" class="WrongQuestion-Page">
            <!-- <ul>
              <li>&lt;</li>
              <li class="on">1</li>
              <li>2</li>
              <li>3</li>
              <li>&gt;</li>
            </ul> -->
            <el-pagination
              layout="prev, pager, next"
              @current-change="currentChangeHandle"
              :current-page="queryForm.page"
              :page-size="queryForm.limit"
              :total="total">
            </el-pagination>
          </div>
          <el-empty v-else description="暂无数据"></el-empty>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import EduHeader from '@/components/EduHeader';
import NavInfo from '@/components/NavInfo';
import { getWrongTopicList } from '@/api/memberWrongTopicsController';
import Topic from '@/components/Question/Topic.vue';
import { getExamCategoryList } from '@/api/examCategoryController';
export default {
  name: 'WroingQuestion',
  data() {
    return {
      activeName: 'Teaching',
      show: false,
      info: '对',
      wrongTopicList: [],
      total: 0,
      index: 0,
      queryForm: {
        page: 1,
        limit: 10,
        order: 'created',
        categoryId: null,
      },
      menuList: [
        {
          id: -1,
          name: '最 新',
          refKind: 'NEW',
        },
        {
          id: -2,
          name: '章 节',
          refKind: 'EXAM_POINTS',
        },
      ],
    };
  },
  created() {
    const id = this.getCourseId(); //sessionStorage.getItem("COURSE_ID");
    this.queryForm.courseId = id;
    this.getExamCategoryList();
    this.getWrongTopicList();
  },
  methods: {
    getCourseId() {
      let id = null;
      const key = 'COURSE_ID';
      if (this.$cookies.isKey(key)) {
        id = this.$cookies.get(key);
      }
      return id;
    },
    showBu(row) {
      this.info = row;
      this.show = false;
    },
    activeClass(_index) {
      if (this.index == _index) {
        return 'on';
      }
    },
    showFn() {
      this.show = !this.show;
    },
    getExamCategoryList() {
      getExamCategoryList({
        __page: 0,
        __pageSize: 100,
        __orderBy: 'idx',
      }).then((res) => {
        if (res.code == 20000) {
          if (res.data.length > 0) {
            var data = res.data;
            const dataLength = data.length;
            for (let i = 0; i < dataLength; i++) {
              data[i].refKind = 'EXAM_TYPE';
            }
            this.menuList = this.menuList.concat(data);
          }
        } else {
          this.$baseMessage(res.data.msg, 'error', 'vab-hey-message-error');
        }
      });
    },
    getWrongTopicList() {
      getWrongTopicList(this.queryForm).then((res) => {
        if (res.code == 20000) {
          this.total = res.total;
          this.wrongTopicList = res.data;
        } else {
          this.$baseMessage(res.data.msg, 'error', 'vab-hey-message-error');
        }
      });
    },
    setWrongTopicCallback() {
      this.wrongTopicList = [];
      this.getWrongTopicList();
    },
    handleOrderChange(menu, index) {
      // if (this.queryForm.order == "-created") {
      //   this.queryForm.order = "created";
      // } else {
      //   this.queryForm.order = "-created";
      // }
      this.index = index;
      if (menu.refKind == 'NEW') {
        this.queryForm.refKind = null;
        this.queryForm.refId = null;
        this.queryForm.categoryId = null;
      } else if (menu.refKind == 'EXAM_POINTS') {
        this.queryForm.refKind = 'Practice';
        this.queryForm.refId = null;
        this.queryForm.categoryId = null;
      } else {
        this.queryForm.refKind = 'Exam';
        this.queryForm.refId = '';
        this.queryForm.categoryId = menu.id;
      }
      this.queryForm;
      this.getWrongTopicList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.queryForm.page = val;
      this.getWrongTopicList();
    },
  },
  components: {
    EduHeader,
    NavInfo,
    Topic,
  },
};
</script>

<style lang="scss" scoped>
.containerMain {
  background: #fafafa;
  position: relative;
  .containerMainRight {
    padding-left: 410px;
    .WrongQuestionCenter {
      width: 700px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      .WrongQuestionInfo {
        background: #f1e5b3;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #deb818;
        padding: 15px;
        .WrongQuestionInfo-title {
          font-size: 16px;
          font-weight: bold;
          color: #060606;
        }
        .WrongQuestionInfo-font {
          font-size: 16px;
          font-weight: 400;
          color: #060606;
          line-height: 24px;
          margin-top: 10px;
          span {
            font-size: 16px;
            font-weight: bold;
            color: #173d7c;
            text-decoration: underline;
            margin-left: 5px;
          }
        }
      }
      .WrongQuestion-Title {
        height: 50px;
        margin-top: 15px;
        .WrongQuestion-font {
          font-size: 36px;
          font-weight: 400;
          color: #060606;
          line-height: 50px;
          float: left;
        }
        .WrongQuestion-select {
          margin-top: 10px;
          height: 30px;
          background: #636363;
          border-radius: 15px 15px 15px 15px;
          opacity: 1;
          border: 1px solid #707070;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          position: relative;
          float: right;
          line-height: 30px;
          .selectValue {
            padding: 0 15px;
            span,
            i {
              float: left;
            }
            i {
              margin-left: 5px;
              margin-top: 3px;
            }
          }
          dl {
            display: none;
            background: #636363 79%;
            border-radius: 15px 15px 15px 15px;
            border: 1px solid #707070;
            position: absolute;
            left: 0;
            top: 30px;
            width: 100%;
            padding: 5px 0;
            dd {
              border-radius: 15px 15px 15px 15px;
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
              padding: 0 15px;
              cursor: pointer;
              &:hover {
                background: #ffcf00;
              }
              &.on {
                background: #ffcf00;
              }
            }
          }
          &:hover dl {
            display: block;
          }
        }
      }
    }
  }
}
.EduHome {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  footer {
    padding: 24px 40px 40px;
    font-size: 16px;
    color: $color-text-6;
    background-color: $color-background-5;
  }
}
.WrongQuestion-or-title {
  line-height: 30px;
  padding: 15px 0 5px 0;
  .orTitle-left {
    padding-right: 150px;
  }
  .orTitle-right {
    float: right;
    span,
    i,
    div {
      float: left;
    }
    i {
      margin-top: 5px;
      height: 25px;
      font-size: 0;
    }
    div {
      margin-left: 10px;
      height: 30px;
      background: #636363;
      display: block;
      border-radius: 16px;
      padding: 0 10px;
      color: #ffffff;
      span {
        margin-left: 5px;
        line-height: 30px;
      }
    }
  }
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}
.WrongQuestion-or-text {
  padding-top: 10px;
  textarea {
    padding: 5px 10px;
    border: 1px solid #707070;
    height: 350px;
    line-height: 24px;
    font-size: 16px;
    width: 100%;
  }
}
.WrongQuestion-explain {
  background: #ffffff;
  border: 1px solid #aeaeae;
  opacity: 1;
  border-radius: 6px;
  padding: 15px 20px;
  margin-top: 20px;
  .explainTitle {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    padding-left: 30px;
    background: url('~@/assets/outline/iconDown.png') no-repeat left;
    background-size: 16px 17px;
    line-height: 36px;
  }
  .explainCenter {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #636363;
    opacity: 1;
    line-height: 24px;
    margin-top: 20px;
  }
  &.on {
    background: #ede2e3;
    border: 1px solid #8d0005;
    opacity: 1;
    border-radius: 6px;
    .explainTitle {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      background: url('~@/assets/outline/naviconitem-3.png') no-repeat left;
    }
    .wrongReason {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #060606;
      opacity: 1;
      margin-top: 20px;
      padding-left: 30px;
    }
    .explainCenter {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #060606;
      opacity: 1;
      padding-left: 30px;
      a:link,
      a:visited {
        color: #8d0005;
      }
    }
  }
}
.WrongQuestion-Page {
  padding: 20px 0;
  text-align: center;
  ul {
    display: inline-block;
    li {
      float: left;
      margin: 0 5px;
      border: 1px solid #eeeeee;
      width: 32px;
      height: 32px;
      background: #ffffff;
      border-radius: 3px 3px 3px 3px;
      border: 1px solid #e9e9e9;
      font-size: 12px;
      font-weight: 300;
      color: #999999;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      &.on {
        width: 32px;
        height: 32px;
        background: #173d7c;
        border-radius: 3px 3px 3px 3px;
        opacity: 1;
        color: #ffffff;
      }
    }
  }
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}
.WrongQuestion-or {
  margin-top: 10px;
  border: 1px solid #d6d6d6;
  opacity: 1;
  border-radius: 6px;
  dl {
    dd {
      .WrongQuestion-orDdTitle {
        padding: 20px 14px;
        label {
          cursor: pointer;
          display: block;
          position: relative;
          input {
            float: left;
            margin-top: 6px;
          }
          div {
            padding-left: 35px;
            padding-right: 35px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #060606;
            opacity: 1;
          }
        }
      }
      .WrongQuestion-info {
        background: #e8e8e8;
        opacity: 1;
        border-radius: 0px;
        padding: 20px 20px 20px 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #060606;
        opacity: 1;
      }
    }
  }
}
</style>
